<template>
  <div>
    <v-dialog max-width="500" v-model="openMoreInfo">
      <v-card>
        <div class="p-5">
          <h3>Introducing Our Leaderboard for Top Traders</h3>
          <div>
            <p>
              Our leaderboard is a monthly celebration of our most dedicated
              traders—the real MVPs of SnappyExchange! Each month, it highlights
              our top 20 users, ranked by their trading activity. The more you
              trade, the higher you rise on the leaderboard.
            </p>
            <p>
              At the end of each month, we reward our top users with exclusive
              prizes that recognize their loyalty and commitment. Some of the
              exciting rewards you can win include:
            </p>
            <ul>
              <li>
                Cash Prizes - Earn real rewards for your trading activity.
              </li>
              <li>Vouchers</li>
              <li>
                Luxury Weekend Getaway - Enjoy a relaxing stay at a premium
                hotel.
              </li>
              <li>
                Round-Trip Flights within Nigeria - Perfect for a quick escape.
              </li>
              <li>
                Customized Fan Jersey - Represent your favorite team in style.
              </li>
              <li>
                SnappyExchange Gift Box - A box filled with our exclusive
                branded items.
              </li>
              <li>
                Airtime and Mobile Data VIP Services Access - Unlock premium
                features reserved for top traders.
              </li>
            </ul>
          </div>
          <p>
            Our team will contact the winners at the end of each month to
            provide the details of their prize. Trade more, climb higher, and
            join the ranks of our top traders to enjoy these amazing rewards!
          </p>
          <p class="italic">Terms and conditions apply.</p>
          <v-btn color="primary" @click="openMoreInfo = false" class="mt-5"
            >Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-card elevation="0" class="my-5">
      <div :class="`grid ${isDashboard ? 'grid-cols-1' : 'grid-cols-2'} gap-5`">
        <div class="px-6 py-3">
          <div class="flex items-center justify-between h-full">
            <div @click="toggleRankDialog" class="relative cursor-pointer">
              <span
                class="absolute left-8 -top-2 px-2 py-1 block bg-accent rounded-full text-xs"
                style="width: max-content; z-index: 1"
              >
                <span
                  class="bg-yellow-800 rounded-full text-white inline-flex w-5 h-5 items-center justify-center"
                >
                  {{ currentRank.level }}
                </span>
                {{ currentRank.title }}
              </span>
              <v-avatar color="primary" size="50">
                <span class="text-white">{{
                  user.firstName | getInitials
                }}</span>
              </v-avatar>
            </div>
            <div class="flex flex-col items-end">
              <span class="text-sm text-gray-500">Username</span>
              <span class="capitalize font-medium text-gray-700">{{
                user.username
              }}</span>
            </div>
          </div>
        </div>
        <div class="px-6 py-3">
          <div class="flex flex-col items-end">
            <p class="text-sm mb-0">
              Compete and Earn! Every month, the top 20 users on our leaderboard
              receive exciting rewards. Climb the ranks and be among the best to
              claim your prize! Find out how to
              <button
                class="underline text-primary"
                @click="openMoreInfo = true"
              >
                learn more here.
              </button>
            </p>
          </div>
        </div>
      </div>
    </v-card>
    <v-card
      color="white"
      class="mb-5 pb-10 full-width py-4 px-5 animate__animated animate__slideInRight darken-2"
      elevation="0"
      rounded="lg"
    >
      <div class="flex items-center justify-between">
        <h4 class="mb-5">Top Traders</h4>
        <div class="w-72 flex-shrink-0">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Filter by Date"
                prepend-icon="mdi-calendar"
                readonly
                width="290px"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </div>
      <div class="leaderboard-tabs">
        <div
          @click="switchTypeTab(0)"
          :class="`${leaderboardTab === 0 && 'active'} leaderboard-tab`"
        >
          Giftcard
        </div>
        <div
          @click="switchTypeTab(1)"
          :class="`${leaderboardTab === 1 && 'active'} leaderboard-tab`"
        >
          Crypto
        </div>
        <!-- <div
          @click="switchTypeTab(2)"
          :class="`${leaderboardTab === 2 && 'active'} leaderboard-tab`"
        >
          Crypto P
        </div> -->
      </div>

      <v-card
        elevation="0"
        v-if="otherUsers.length === 0 && !loadingData"
        class="text-center"
      >
        <h3>No user on leaderboards yet</h3>
        <p>
          Be the first,
          <router-link to="/user/products/giftcards">Sell Giftcard</router-link>
          or
          <router-link to="/user/products/crypto">Trade Crypto</router-link>
        </p>
      </v-card>

      <v-card elevation="0" :loading="loadingData" class="mt-8">
        <v-virtual-scroll
          :items="otherUsers"
          :item-height="60"
          :height="otherUsers.length > 0 ? '220' : '150'"
        >
          <template v-slot:default="{ item }">
            <div class="d-flex w-100 justify-space-between align-center">
              <div class="d-flex">
                <div class="mr-3">
                  <v-avatar :color="getColorBg()" :size="30"
                    >{{ item.firstName | getInitials }}
                  </v-avatar>
                </div>
                <div>
                  <p :title="item.username" class="text-body-1 mb-0">
                    {{ item.username }}
                  </p>
                  <h4 class="font-weight-bold subtitle-2 primary--text mb-0">
                    {{ item.amount | formatAmount }} points
                  </h4>
                </div>
              </div>
              <div class="text-body-2 text-right position">
                {{ item.position }}
              </div>
            </div>
          </template>
        </v-virtual-scroll>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import dataApi from '../api/systemData'
import { getCurrencySymbol } from '../utils/countryHelpers'
import rank from '@/api/users/rank'

export default {
  name: 'Leaderboard',
  props: {
    isDashboard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      dates: [
        dayjs().startOf('month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ],
      openMoreInfo: false,
      getCurrencySymbol,
      date: {
        from: dayjs().startOf('month').format('YYYY-MM-DD'),
        to: dayjs().format('YYYY-MM-DD')
      },
      userPosition: 0,
      leaderboardTab: 0,
      users: [],
      selection: 0,
      loadingData: false,
      currentRank: {},
      nextRank: {},
      totalAmountTrader: 0
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    dateRangeText() {
      return this.dates.join(' - ')
    },
    otherUsers() {
      if (this.users.length <= 0) return []
      return this.users
    }
  },
  created() {
    this.getLeaderboard({
      category: this.leaderboardTab
    })
    this.getCurrentRank()
  },
  methods: {
    async toggleRankDialog() {
      this.$store.commit('user/toggleRankDialog')
    },
    async getCurrentRank() {
      const res = await rank.rankApi().getUserCurrentRank()

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })

        return
      }

      this.currentRank = res.data.data.currentRank
      this.nextRank = res.data.data.nextRank
      this.totalAmountTrader = res.data.data.totalAmount
    },
    switchTypeTab(index) {
      this.leaderboardTab = index
    },
    async getLeaderboard({ category }) {
      this.loadingData = true

      const categoryText = category === 0 ? 'giftcard' : 'coin'
      const typeText = category === 0 || category === 1 ? 'sale' : 'purchase'

      const res = await dataApi.data().getLeaderboard({
        from: this.date.from,
        to: this.date.to,
        category: categoryText,
        type: typeText,
        requester: 'user',
        country: this.user.country
      })

      this.users = res.data.data.leaderboard

      this.userPosition = res.data.data.leaderboard.findIndex(
        (user) => user.username === this.user.username
      )

      this.loadingData = false
    },
    getColorBg() {
      const colors = [
        'primary',
        'accent',
        'pink',
        'brown',
        'green',
        'purple',
        'yellow',
        'red',
        'indigo',
        'blue',
        'light-blue',
        'teal',
        'cyan',
        'green',
        'light-green',
        'lime',
        'amber',
        'orange',
        'blue-grey'
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    }
  },
  watch: {
    leaderboardTab: function (newValue, oldValue) {
      // 0 - Giftcard
      // 1 - Coin Sale
      // 2 - Coin Purchase
      if (oldValue !== newValue)
        this.getLeaderboard({
          category: this.leaderboardTab
        })
    },
    dates: function (newValue, oldValue) {
      const firstDate = dayjs(newValue[0])
      const secondDate = dayjs(newValue[1])

      if (newValue[0] && newValue[1]) {
        if (firstDate.isAfter(secondDate)) {
          this.date = {
            from: newValue[1],
            to: newValue[0]
          }
        } else {
          this.date = {
            from: newValue[0],
            to: newValue[1]
          }
        }

        this.getLeaderboard({
          category: this.leaderboardTab
        })

        return
      }

      if (!newValue[0] || !newValue[1]) {
        this.date = {
          from: newValue[0] || newValue[1],
          to: newValue[0] || newValue[1]
        }
        this.getLeaderboard({
          category: this.leaderboardTab
        })
      }
    }
  },
  filters: {
    truncateUsername(value) {
      if (!value) return ''
      const length = value.length
      const show = Math.floor(length * 0.6)
      return value.slice(0, show) + '***'
    },
    getInitials(value) {
      if (!value) return 'AA'
      return value[0]
    },
    formatAmount(value) {
      if (!value) return 0
      return Number.parseFloat(value).toFixed(2)
    }
  }
}
</script>

<style>
.leaderboard-tabs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #0d01c7;
  border-radius: 40px;
  color: white;
}

.leaderboard-tab {
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
}

.leaderboard-tab.active {
  font-size: 14px;
  background-color: #eabb23;
  color: #0d01c7;
  font-weight: 600;
  transition: 0.7s;
}

.leaderboard-tabs-period {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  border-radius: 40px;
  color: rgb(193, 193, 193);
}

.leaderboard-tab-period {
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border-radius: 40px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.leaderboard-tab-period.active {
  font-size: 14px;
  color: #0d01c7;
  font-weight: 600;
  transition: 0.7s;
}

.leading-group {
  position: relative;
  display: flex;
  margin-top: 6rem;
  margin-bottom: 7rem;
}

.leading-1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.leading-2 {
  position: absolute;
  top: 0;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.leading-3 {
  position: absolute;
  top: 0;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.leading-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leading-tabs .money {
  margin: 0;
  margin-top: 7px;
  color: #0d01c7;
  font-weight: 600;
  padding: 0;
  font-size: 15px;
}

.leading-tabs .name {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 12px;
}

.position {
  background: #a9a9a9;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.position_absolute {
  position: absolute;
  top: -15px;
  z-index: 5;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  box-shadow: 0 0 12px rgb(0 0 0 / 20%);
}
</style>
